<template>
  <FormCard v-if="ppl" :config="gen_data" @DataSaved="UpdateData" />
  <div v-else class="loading">
    <CSpinner color="secondary" size="lg" />
  </div>
</template>
<script>
import { GetDataManager } from "../../../ds/index";
import FormCard from "../../../common/form/Card";
import GetOptions from "../../../common/form/options";

export default {
  model: {
    prop: "ppl",
    event: "change",
  },
  props: ["ppl", "cur_lang"],
  components: {
    FormCard,
  },
  data() {
    return {
      need_nation_options: {},
    };
  },
  methods: {
    UpdateData(data) {
      // console.log("UpdateData")
      this.$emit("change", data);
    },
  },
  created() {
    GetOptions({
      dm: GetDataManager("structure_production"),
      query_cache: "structure_production",
      reducer: function (map, obj) {
        if (obj.need_nation) {
          map.push(obj.id);
        }
        return map;
      },
      reducer_cache: "structure_production_need_nation",
    }).then((e) => {
      this.need_nation_options = e;
    });
  },
  computed: {
    need_country() {
      return this.need_nation_options.length > 0
        ? this.need_nation_options.includes(this.ppl.production_type_id)
        : false;
    },

    gen_data() {
      return {
        title: this.$t("role.product.lots.card_data.title"),
        data: this.ppl,
        fields: {
          name: {
            title: this.$t("models.lot.name"),
            description: this.$t("models.lot.name_info"),
            type: "CInput",
            size: 12,
            subsize: [3, 9],
            translate: "translations",
          },
          production_type_id: {
            title: this.$t("models.lot.production_type"),
            description: this.$t("models.lot.production_type_info"),
            type: "CSelect",
            size: 12,
            subsize: [3, 9],
            options: GetOptions({
              dm: GetDataManager("structure_production"),
              query_cache: "structure_production",
              cur_lang: this.cur_lang,
            }),
          },
          production_type_nation_id: {
            title: this.$t("models.lot.production_type_nation"),
            description: this.$t("models.lot.production_type_nation_info"),
            type: "CSelect",
            size: 12,
            subsize: [3, 9],
            options: GetOptions({
              dm: GetDataManager("geo_country"),
              reducer: function (map, obj) {
                map.push({ value: obj.code, label: obj.name });
                return map;
              },
              reducer_cache: "geo_country",
            }),
            enabled: this.need_country,
          },
          producer_code: {
            title: this.$t("models.lot.producer_code"),
            description: this.$t("models.lot.producer_code_info"),
            type: "CInput",
            size: 12,
            subsize: [3, 9],
            hide: true,
          },
          last_min: {
            title: this.$t("models.lot.last_min"),
            description: this.$t("models.lot.last_min_info"),
            size: 12,
            subsize: [3, 9],
            hide: true,
            children: {
              last_min_n: {
                type: "CInput",
                size: 4,
                subsize: [0, 12],
                sep: false,
              },
              last_min_t: {
                type: "CSelect",
                size: 8,
                options: [
                  { value: "d", label: "giorni" },
                  { value: "m", label: "mesi" },
                  { value: "y", label: "anni" },
                ],
                sep: false,
                subsize: [0, 12],
              },
            },
          },
          lot_description: {
            title: this.$t("models.lot.lot_description"),
            description: this.$t("models.lot.lot_description_info"),
            type: "CKInput",
            size: 12,
            subsize: [3, 9],
            translate: "translations",
            hide: true,
          },
        },
        dm: GetDataManager("role_producer_products_lots", [
          this.$store.state.role.id,
        ]),
      };
    },
  },
};
</script>
